* {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
  font-size: 3vmin;
  font-family: monospace;
  margin-bottom: 1vmin;
}

.cell {
  width: 20px;
  height: 20px;
  border: 'solid 1px black';
  text-align: 'center';
}

.container {
  display: grid;
  width: 75vmin;
  grid-gap: 1px;
  background-color: black;
  border: 1px solid black;
  margin-bottom: 1vmin;
}

.controls {
  grid-template-columns: repeat(5, 1fr);
}

.automatas {
  grid-template-columns: repeat(3, 1fr);
}

.item {
  background-color: white;
  border: solid 0px black;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vmin;
  min-width: 8vmin;
  min-height: 4vmin;
  cursor: pointer;
}

footer {
  margin-top: 1vmin;
}

.link {
  height: 4vmin;
  width: 4vmin;
  padding: 0 1vmin;
}

.hide {
    background-color: #ffffff;
    cursor: not-allowed;
    opacity: 0.95;
    color: rgba(0, 0, 0, 0.3);
}
